<template>
  <v-container style="position: relative; padding-left:2.5cm; padding-right:2.5cm"  id="pdfBudgetPlan">
    <v-row >
      <v-col  class=" offset-sm-1">
        Gesamtbudget:
        <a>
          <h2>
            <span> {{ replaceDot(budget) }}€ </span>
          </h2>
        </a>
      </v-col>
      <v-col style="text-align: right" >
        Geschätzte Kosten:
        <h2>{{ replaceDot(budgetPlan.sumPlanned) }}€</h2>
      </v-col>
      <v-col style="text-align: right">
        Bereits ausgegeben:
        <h2 v-if="budgetPlan.sumSpent >budget" style="color:darkred">
          {{ budgetPlan.sumSpent }}€</h2>
        <h2 v-else>{{ replaceDot(budgetPlan.sumSpent) }}€</h2>
      </v-col>
    </v-row>
      <div v-for="item of budgetPlan.budgetList" :key="item.id" style="margin-top: 40px">
        <v-row>
          <v-col cols="1"  class="text-center">
            <v-icon slot="prependIcon" size="35">
              {{ item.icon }}
            </v-icon>
          </v-col>
          <v-col  >
            {{ item.name }}
          </v-col>
          <v-col  style="text-align: right">
            <span>Geplant:</span><br>
            <b>{{ replaceDot(item.sumPlanned) }}€</b>
          </v-col>

          <v-col style="text-align: right">
            <span >Ausgegeben:</span><br>
            <b >
              {{ replaceDot(item.sumSpent) }}€
            </b>
          </v-col>
        </v-row>
        <v-row v-for="budget of item.singleBudget" :key="budget">
          <v-col cols="1" class="text-center">
          </v-col>
          <v-col>
            {{ budget.name }}
          </v-col>
          <v-col style="text-align: right">
            {{ replaceDot(budget.planned) }}€
          </v-col>
          <v-col style="text-align: right">
            {{ replaceDot(budget.spent) }}€
          </v-col>
        </v-row>
      </div>

  </v-container>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import {dotToKomma, error} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
   // Doughnut
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default(){
        return ''
      }
    },
    budgetPlan: {
      type: Object,
      default(){
        return {}
      }
    },
    chartDataBudget: {
      type: Object,
      default(){
        return {}
      }
    },
    chartDataCategories: {
      type: Object,
      default(){
        return {}
      }
    },
    categorySums: Object,
    budget: Number
  },
  methods: {
    replaceDot(float) {
      return dotToKomma(float)
    },
    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    changeName(name) {
      this.$store.dispatch('customer/editField', {
        entity: "budgetPlan",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: name,
        row: 'name'
      }).then(() => {
        this.budgetPlan.name = name
      }).catch((err) => {
        this.message = error(err)
      })
    },
  },


}
</script>

<style>
#pdfBudgetPlan * {
  font-weight: unset;
  font-size: unset
}

h1 {
  font-weight: bold;
  font-size: larger;
  padding-top: 20px
}

h2 {
  font-weight: bold;
  font-size: unset;
}

@page {
  margin: 0;
  padding-top: 1555px;
}

.breakText {
  break-before: page;
  margin-top: 30px;
}
</style>

