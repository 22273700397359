<template>
  <v-container class="about">
    <Invoice
        v-if="$route.path.includes('/invoice/') && loaded"
      :invoiceSettings="invoiceSettings"
      :invoice="invoice"
      :positions="positions"
        :profilePic="profilePic"
    >
    </Invoice>

    <BudgetPlan
        v-else-if="$route.path.includes('/budgetPlan/') && loaded"
      :budgetPlan="budgetPlan1"
        :budget="budget1"
        :profilePic="profilePic"

    >
    </BudgetPlan>
  </v-container>
</template>
<script>


import Invoice from "@/components/PdfExport/Invoice.vue";
import BudgetPlan from "@/components/PdfExport/budgetPlan.vue";

import {mapGetters} from "vuex";
import {error} from "@/helper/helper";
export default {
  name: 'PDF',
  components: {
    Invoice,
    BudgetPlan
  },
  props:{
  },
  data(){
    return {
      loaded: false,
      budget1: null,
      budgetPlan1: null,
      profilePic: ''
    }
  },
  computed: {
    ...mapGetters('invoice', {
      invoice: 'invoice',
      positions: 'positions',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings',
    }),
    ...mapGetters('budgetPlan', {
      budget: 'budget',
      budgetPlan: 'budgetPlan',
      categoriesBudget: 'categoriesBudget'
    }),
  },
  mounted() {
    const elements = document.getElementsByClassName('iubenda-tp-btn');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    const element = document.getElementById('footerLeiste');

    element.parentNode.removeChild(element);

    this.$store.dispatch('file/getProfilePic').then((profilePicUrl) => {
      this.profilePic= profilePicUrl
      console.log(profilePicUrl)
      this.$store.dispatch('auth/checkLogin', {}).then((res) => {
        if(this.$route.path.includes('/invoice/')){
          if( this.invoice.isQuote){
            document.title = 'Angebot_'+ this.invoice.invoiceNumber
          }else{
            document.title = 'Rechnung_'+ this.invoice.invoiceNumber
          }
          this.$store.dispatch('invoice/getInvoice', {
            uid: res,
            invoiceId: this.$route.params.id
          }).then(() => {
            this.$store.dispatch('settings/getInvoiceSettings', {
              uid: res,
            }).then(()=>{
              this.loaded=true
              window.scrollTo(0, 0)
              setTimeout(()=>{
                print()
                this.$router.push('/rechnungen/rechnung/'+this.invoice.id).then(()=>{
                  location.reload()
                })
              },500)
            }).catch((err)=>{
              console.log(err)
            })
          }).catch((err)=>{
            console.log(err)
          })
        }else if(this.$route.path.includes('/budgetPlan/')){
          this.$store.dispatch('budgetPlan/getFullPlan', {
            budgetPlanId: this.$route.params.id
          }).then(() => {
            this.budget1 =this.budget
            this.budgetPlan1 = this.budgetPlan
            this.loaded=true
            setTimeout(()=>{
              print()
              this.$router.push('/weddings/wedding/'+this.budgetPlan.weddingId+'/budgetPlan/'+this.budgetPlan.id).then(()=>{
                location.reload()
              }).finally(()=>{
                this.loaded=true
              })
            },1000)

          }).catch((err) => {
            this.message = error(err)
          })
        }
      }).catch((err)=>{
        console.log(err)
      })
    })

  },
}
</script>
<style>


</style>
